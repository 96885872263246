<template>

  <div>
    <!--    <div v-show="!isFly" style="position: fixed;width: 100%;height: 100%;z-index: -1"-->
    <!--         :style="{backgroundImage:'url('+defaultbg+')'}"></div>-->


    <div style="position: fixed;top:0;left:0;right:0;bottom:0;z-index: 9999!important;background: rgba(0,0,0,0.55)" v-if="blocked"  @click="blocked=false">
      <div
          style="position: fixed;top: 35%;left: 50%;transform: translate(-50%,-50%);width: 35%;max-height: 500px;">
        <div style="float: right;font-size: x-large;color: red">
          <a-icon type="close"/>
        </div>
        <img :src="require('@/assets/blocked.png')" alt="" style="width: 100%;height: 100%">
      </div>
    </div>


    <div
        style="position: fixed;top:0;left:0;right:0;bottom:0;background-size: cover;background-position: center;z-index: 500;display: flex;flex-wrap: wrap;justify-content: center;align-items: center"
        v-if="startDownload"
        :style="{backgroundImage:`url('${require('@/assets/process.jpg')}')`}"
    >

      <div style="width: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center">
        <a-progress :stroke-color="{
                '0%': '#108ee9',
                '100%': '#87d068',
              }" :strokeWidth="6" type="circle" :percent="downloading" style=""/>
        <div style="width: 100%;text-align: center;margin-top: 15px;font-size: 25px;color: darkcyan">{{
            startDownloading
          }} {{ 180 - this.timeoutSec }}
          <a-icon type="loading" style="color: cyan;font-size: 25px"/>
        </div>
      </div>
    </div>


    <a-affix :offset-top="top" style="position: absolute;right:15px;top: 80px;"
             v-if="showX && isPay == 1 && !isFly">
      <div style="display: flex;flex-wrap: nowrap;justify-content: center;width:350px">
        <div style="margin-right: 15px;">
          <div style="display: flex;justify-content: right;">
            <a-button type="primary" @click="openTemp">{{ isOpen }}</a-button>
          </div>

          <transition name="animate__animated animate__bounce" leave-active-class="animate__slideOutRight"
                      enter-active-class="animate__slideInUp" appear>
            <div
                style="margin-top: 10px;height: 688px;background: rgba(0,0,0,0.3);border-radius: 9px;width: 350px;position: absolute;left: 0;overflow:auto;"
                v-if="imgTemp" class="animate__backInDown">
              <hhh group="gun" style="overflow:auto;width: 100%;float:left;height: 100%" :list="cacheList"
                   @add="cacheAdd">
                <div v-for="item in cacheList" :key="item.classid" class="imgFor-cache" id="small">
                  <img :src="item.icon_url" :title="item.name" :alt="item.classid" width="100%"
                       height="auto">
                </div>
              </hhh>
            </div>
          </transition>
        </div>


        <div>
          <div style="display: flex;justify-content: right;">
            <a-button type="primary" @click="openMaterial">{{ isMaterial }}</a-button>
          </div>

          <transition name="animate__animated animate__bounce" leave-active-class="animate__slideOutRight"
                      enter-active-class="animate__slideInUp" appear>

            <div
                style="margin-top: 10px;height: 688px;background: rgba(0,0,0,0.3);border-radius: 9px;width: 350px;position: absolute;left: 0;overflow:auto;"
                v-if="isOpenMaterial">
              <hhh group="gun" style="width: 100%;display: flex;flex-wrap: wrap;height: 100%" :list="material"
                   @add="cacheAdd" @change="log">
                <div v-for="item in material" :key="item.classid" class="imgFor-cache" id="small">
                  <img :src="item.icon_url" :title="item.name" :alt="item.classid" width="100%"
                       height="auto">
                </div>

              </hhh>

            </div>
          </transition>
        </div>


      </div>
    </a-affix>

    <div class="navigation" v-show="!isFly"></div>
    <!--    <div class="download_button_box" v-show="!isFly">-->
    <!--      <div class="reWrite">-->
    <!--        遮罩层调整:-->
    <!--        <a-slider :default-value="bgWriten" v-model="bgWriten" style="width: 100%"/>-->
    <!--      </div>-->
    <!--      <div class="reWrite">-->
    <!--        物品描述大小调整:-->
    <!--        <a-slider :default-value="sayFs" v-model="sayFs" :max="40" :min="10" style="width: 100%"/>-->
    <!--      </div>-->
    <!--      &lt;!&ndash;      <div class="reWriteX" v-html="this.website_info.website_info[0].page_notice"></div>&ndash;&gt;-->
    <!--    </div>-->

    <!--左边菜单-->
    <transition-group name="left-menu" appear v-show="!isFly">
      <div class="left_menu" style="z-index: 50" v-show="showMenu" key="menu_all">
        <!--        主菜单-->

        <div style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;overflow: hidden;padding:12px"
        >
          <transition name="animate__animated animate__bounce" enter-active-class="animate__backInUp"
                      leave-active-class="animate__bounceOutLeft"
          >
            <div style="display: flex;flex-wrap: wrap;justify-content: center;"
                 v-show="menuStatus.menu_all"
                 v-if="isPay == 1">
              <!--              <el-button round size="small" type="danger" icon="el-icon-s-home"-->
              <!--                         style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"-->
              <!--                         @click="$router.push('/vip/getLibrary')">-->
              <!--                返回主页-->
              <!--              </el-button>-->

              <a-popover trigger="click" placement="left">
                <template slot="content">
                  <ColorPicker
                      type="gradient"
                      :titleConfig='{text:"顶部描述颜色",show: false}'
                      @changeColor="topTipsColorful"
                      :pDeg="90"
                      :pColors="topTipsColors"/>
                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="redo"
                >
                  顶部描述颜色
                  <div :style="{backgroundImage:topTipsBackground}"
                       style="width:25px;height: 25px;border: 1px solid aqua;border-radius: 50%;float: right;"></div>
                </a-button>
              </a-popover>


              <a-popover trigger="click" placement="left">
                <template slot="content">
                  <ColorPicker
                      type="gradient"
                      :titleConfig='{text:"物品描边颜色",show: false}'
                      @changeColor="itemBorderColorful"
                      :pDeg="90"
                      :pColors="itemBorderColors"/>
                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="redo"
                >
                  物品描边颜色&nbsp;
                  <div :style="{backgroundImage:itemBorderBackground}"
                       style="width:25px;height: 25px;border: 1px solid aqua;border-radius: 50%;float: right;"></div>
                </a-button>
              </a-popover>

              <a-popover trigger="click" placement="left">
                <template slot="content">
                  <ColorPicker
                      type="linear"
                      :titleConfig='{text:"物品描述颜色",show: false}'
                      @changeColor="describeColorful"
                      :pDeg="90"
                      :pColor="describeColor"/>
                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="redo"
                >
                  物品描述颜色 &nbsp;
                  <div :style="{backgroundColor:describeBackground}"
                       style="width:25px;height: 25px;border: 1px solid aqua;border-radius: 50%;float: right;"></div>
                </a-button>
              </a-popover>


              <a-popover trigger="click" placement="left">
                <template slot="content">
                  <ColorPicker
                      type="linear"
                      :titleConfig='{text:"商品名称颜色",show: false}'
                      @changeColor="titleColorful"
                      :pDeg="90"
                      :pColor="titleBackground"/>
                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="redo"
                >
                  商品名称颜色 &nbsp;
                  <div :style="{backgroundColor:titleBackground}"
                       style="width:25px;height: 25px;border: 1px solid aqua;border-radius: 50%;float: right;"></div>
                </a-button>
              </a-popover>


              <div class="download_button" style="z-index: 30 !important;">
                <a-icon type="bg-colors"/>&nbsp;底部背景颜色
                <el-color-picker style="transform: scale(0.6)"
                                 v-model="bgColor"
                                 show-alpha
                                 :predefine="predefineColors">
                </el-color-picker>
              </div>


              <a-tooltip trigger="hover" placement="right">
                <template slot="title">
              <div style="width: 180px">
                <a-button size="large" ghost shape="round" type="primary" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="file-image" @click="setSize(1)">
                  一行7个
                </a-button>

                <a-button size="large" ghost shape="round" type="primary" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="file-image" @click="setSize(2)">
                  一行9个
                </a-button>

                <a-button size="large" ghost shape="round" type="primary" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="file-image" @click="setSize(3)">
                  一行13个
                </a-button>


                <a-button size="large" ghost shape="round" type="primary" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="file-image" @click="setSize(4)">
                  一行16个
                </a-button>


                <a-button size="large" ghost shape="round" type="primary" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="file-image" @click="setSize(5)">
                  一行18个
                </a-button>
              </div>
                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="picture"
                >
                  显示模式
                </a-button>
              </a-tooltip>


              <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                        style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                        icon="reddit"
                        @click="customGuns">
                套装归类
              </a-button>

              <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                        style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                        icon="shop"
                        @click="addProduct">
                加商品行
              </a-button>

              <a-tooltip trigger="hover" placement="right">
                <template slot="title">

                  <a-button ghost size="large" type="primary" shape="round" v-if="!Phone"
                            style="width: 200px;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                            class="a-button-height"
                            icon="check" @click="showTitleMsg = false">开启名称
                  </a-button>

                  <a-button ghost size="large" type="primary" shape="round"
                            class="a-button-height" v-if="!Phone"
                            style="width: 200px;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                            icon="close" @click="showTitleMsg = true">关闭名称
                  </a-button>


                  <div class="reWrite">
                    文字大小调整:
                    <a-slider :default-value="titleDefaultSize" @afterChange="sliderEnded" :max="60" :min="10"
                              style="width: 100%"/>
                  </div>

                </template>

                <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                          icon="edit"
                >
                  商品名称

                </a-button>
              </a-tooltip>


              <a-tooltip trigger="hover" placement="right">
                <template slot="title">
                  <div>点击顶部描述的文字可直接更改</div>
                </template>

                <div class="download_button" style="z-index: 30 !important;">
                  <a-icon type="edit"/>&nbsp;顶部描述
                </div>
              </a-tooltip>

              <a-popover placement="left" trigger="click">
                <template slot="content">
                  <div style="display: flex;justify-content: center">
                    <a-select style="width: 120px" v-model="seleted_font">
                      <a-select-option :value="item.face" v-for="(item,i) in font_list" :key="i">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </div>

                </template>
                <div class="download_button" style="z-index: 30 !important;">
                  <a-icon type="bg-colors"/>&nbsp;字体设置
                </div>
              </a-popover>


              <a-popconfirm placement="left" ok-text="确定" cancel-text="取消使用"
                            @confirm="useColorFilter(true)"
                            @cancel="useColorFilter(false)">
                <template slot="title">
                  <div style="width: 300px;display: flex;">
                    <div
                        style="width:70%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">
                        <div style="flex:3;font-size: small;font-weight: bold">灰度:</div>
                        <div style="flex:7">
                          <a-slider :defaultValue="filterValue.grayscale" @afterChange="colorChange($event,'grayscale')" :min="0" :step="10"
                                    :max="100"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">
                        <div style="flex:3;font-size: small;font-weight: bold">高斯模糊:</div>
                        <div style="flex:7">
                          <a-slider  :defaultValue="filterValue.blur" @afterChange="colorChange($event,'blur')" :min="0" :max="50"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">
                        <div style="flex:3;font-size: small;font-weight: bold">深褐色:</div>
                        <div style="flex:7">
                          <a-slider :defaultValue="filterValue.sepia"  @afterChange="colorChange($event,'sepia')" :min="0" :step="10" :max="100"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">
                        <div style="flex:3;font-size: small;font-weight: bold">饱和度:</div>
                        <div style="flex:7">
                          <a-slider :defaultValue="filterValue.saturate"  @afterChange="colorChange($event,'saturate')" :min="0" :step="10"
                                    :max="200"/>
                        </div>

                      </div>


                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">

                        <div style="flex:3;font-size: small;font-weight: bold">透明度:</div>
                        <div style="flex:7">

                          <a-slider :defaultValue="filterValue.opacity"  @afterChange="colorChange($event,'opacity')" :min="0" :step="10" :max="100"/>
                        </div>
                      </div>


                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">

                        <div style="flex:3;font-size: small;font-weight: bold">亮暗度:</div>
                        <div style="flex:7">

                          <a-slider :defaultValue="filterValue.brightness"  @afterChange="colorChange($event,'brightness')" :min="0" :step="10"
                                    :max="500"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">

                        <div style="flex:3;font-size: small;font-weight: bold">对比度:</div>
                        <div style="flex:7">

                          <a-slider  :defaultValue="filterValue.contrast"  @afterChange="colorChange($event,'contrast')" :min="0" :step="10"
                                     :max="100"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">

                        <div style="flex:3;font-size: small;font-weight: bold">色相旋转:</div>
                        <div style="flex:7">

                          <a-slider  :defaultValue="filterValue.hueRotate"  @afterChange="colorChange($event,'hueRotate')" :min="-3600" :step="10"
                                     :max="3600"/>
                        </div>
                      </div>

                      <div style="display: flex;flex-direction: row;width: 100%;flex-wrap: nowrap;line-height: 40px">

                        <div style="flex:3;font-size: small;font-weight: bold">色反转:</div>
                        <div style="flex:7">
                          <a-slider  :defaultValue="filterValue.invert"  @afterChange="colorChange($event,'invert')" :min="0" :step="10" :max="200"/>
                        </div>
                      </div>
                    </div>

                    <div
                        style="width: 30%;margin-left: 20px;display: flex;justify-content: center;align-items: center;"
                        :style="{filter:`grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})`}">
                      <img :src="require('@/assets/colorful.png')" alt="" height="30%">
                    </div>
                  </div>
                </template>
                <div class="download_button" style="z-index: 30 !important;" v-if="!Phone">
                  <a-icon type="bg-colors"/>&nbsp;滤镜设置
                </div>
              </a-popconfirm>



              <label class="download_button" style="z-index: 30 !important;"><input type="file"
                                                                                    style="display: none"
                                                                                    @change="customHeadBg"
                                                                                    accept="image/*">
                <a-icon type="cloud-upload"/>&nbsp;头部背景</label>


              <a-button size="large" type="primary" ghost shape="round" class="a-button-height"
                        style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                        icon="redo"
                        @click="randomBg()">
                随机背景
              </a-button>

              <label class="download_button" style="z-index: 30 !important;"><input type="file"
                                                                                    style="display: none"
                                                                                    @change="customBg"
                                                                                    accept="image/*">
                <a-icon type="upload"/>&nbsp;自定背景</label>


              <a-button size="large" shape="round" class="a-button-height"
                        style="border: 1px solid rgba(230,162,60,1);background: rgba(230,162,60,1);width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;z-index: 30 !important;"
                        icon="book" @click="go_help">
                网站使用教程
              </a-button>


            </div>
          </transition>

        </div>


        <!--        下载菜单-->
        <div style="position: absolute;left: 0;right: 0;top: 0;bottom: 0;padding: 15px;overflow: hidden">

          <transition name="animate__animated animate__bounce" enter-active-class="animate__backInUp"
                      leave-active-class="animate__bounceOutLeft">
            <div style="display: flex;flex-wrap: wrap;justify-content: center;z-index: 30"
                 v-show="menuStatus.menu_download || isPay ==0">
              <a-tooltip placement="rightTop">
                <template slot="title">
                  <span>本地下载速度和电脑配置挂钩,速度快</span>
                </template>
                <a-button ghost size="large" type="primary" shape="round" class="a-button-height" v-if="!Phone"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                          icon="download" @click="local_download(1)">本地原图下载
                </a-button>
              </a-tooltip>


              <a-tooltip placement="rightTop">
                <template slot="title">
                  <span>服务器下载速度较慢,建议配置较低的电脑使用</span>
                </template>

                <a-button ghost size="large" type="primary" shape="round"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                          class="a-button-height"
                          icon="cloud-download" @click="swithToImg(1)">服务器原图下载
                </a-button>
              </a-tooltip>


              <a-tooltip placement="rightTop">
                <template slot="title">
                  <span>本地下载速度和电脑配置挂钩,速度快</span>
                </template>
                <a-button ghost size="large" type="primary" shape="round" v-if="!Phone"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                          class="a-button-height"
                          icon="download" @click=local_download(2)>本地压缩下载
                </a-button>
              </a-tooltip>


              <a-tooltip placement="rightTop">
                <template slot="title">
                  <span>服务器下载速度较慢,建议配置较低的电脑使用</span>
                </template>


                <a-button ghost size="large" type="primary" shape="round"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                          class="a-button-height"
                          icon="cloud-download" @click="swithToImg(2)">服务器压缩下载
                </a-button>
              </a-tooltip>

              <a-tooltip placement="rightTop">
                <template slot="title">
                  <span>此选项出图较大,清晰,速度快</span>
                </template>
                <a-button ghost size="large" type="primary" shape="round" class="a-button-height" v-if="!Phone"
                          style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                          icon="download" @click="local_download(3)">超清原图下载
                </a-button>
              </a-tooltip>

              <a-button ghost size="large" type="danger" shape="round"
                        style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                        class="a-button-height" @click="$router.push('/personality/Payment')"
                        icon="shop" v-if="isPay == 0">前往购买点数
              </a-button>

              <a-button ghost size="large" shape="round" type="danger"
                        style="width: 100%;margin-top: 10px;font-size: 18px;color: white;font-weight:bold;"
                        icon="undo"
                        v-if="isPay == 1"
                        class="a-button-height"
                        @click="()=>{
                      menuStatus.menu_download=false
                    menuStatus.menu_all=true
                    }"
              >返回主菜单
              </a-button>
            </div>

          </transition>
        </div>

        <a-button class="left_menu_button" type="danger" shape="circle" icon="left" @click="showMenu=!showMenu">
        </a-button>
      </div>


      <!--左边菜单-->
      <div class="left_menu_hide" style="z-index: 50" v-show="!showMenu" key="lefted_menu">
        <div class="left_menu_button">
          <a-button type="danger" shape="circle" icon="right" @click="showMenu=!showMenu">
          </a-button>
        </div>
      </div>
    </transition-group>

    <!--    图片块-->
    <div id="download" ref="download" :style="{borderRadius:'19px'}"
         :class="{
        noFly:!isFly,
           fly:isFly,
            download_1600:is1600,
            download:!is1600,
           }">

      <div
          :style="{borderRadius:'19px',backgroundImage:'url('+inputbg+')',width:'100%',height:'100%',backgroundSize: '100%'}">
        <div :style="{height: '100%',width: '100%',borderRadius: '19px',backgroundColor: bgColor}">
          <div
              :style="{height: '100%',width: '100%',borderRadius: '19px',backgroundColor: 'rgba(0,0,0,'+bgWriten/100+')'}">


            <div style="width:100%;border-top-left-radius: 19px;border-top-right-radius: 19px;overflow: hidden">
              <img :src="item" @click.right="deleteHeadBg(i)" alt="" height="100%" v-for="(item,i) in headBg_list"
                   :key="i" width="100%">
            </div>


            <div type="text" class="describe bg-clip-text" contenteditable="true"
                 :style="{fontSize:fs,backgroundImage:topTipsBackground,fontFamily:seleted_font}"
                 style="overflow: hidden;text-overflow: clip;white-space: pre-wrap;letter-spacing:2px;">
              {{ topTips }}
            </div>

            <div style="color: red;font-size: x-large;font-weight: bold;text-align: center" v-if="is_black">
              此账号是黑名单,疑似找回号
              请谨慎交易
            </div>

            <div class="download_img_box"
                 v-if="isPay == 1">

              <hhh v-model="bagJs.seasonTicket"
                   style="display: flex;justify-content: center;flex-wrap: wrap;width: 100%">
                <div v-for="(item,i) in bagJs.seasonTicket" :key="i" style="position: relative"
                     :class="{'imgFor-Season':!seasonTickLarge && !is1600,'imgFor-Season_large':seasonTickLarge && !is1600,'imgFor-Season_large_1600':is1600}"
                     v-show="!seasonShow[i]">
                  <a-popconfirm title="是否删除?" ok-text="是" cancel-text="否" @confirm="deleteSeasonTicket(i)">

                    <img :src="`/txz_image/${item.img_id}.png`" :title="item.season_name" v-if="item.id!=999"
                         :style="{filter:useColorFul ? `grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})` : ''}"
                         :alt="item.item_name" width="100%" height="auto">


                    <div>
                      <div v-if="item.id==999" contenteditable
                           :style="{fontSize:seasonTickLarge ?  is1600 ?  isDeluxUser ? '29px' : '25px' : isDeluxUser ? '21px' : '18px' : isDeluxUser ? '33px' : '27px' ,bottom:seasonTickLarge ? is1600 ? '9px' : '12px':'17px'}"
                           style="position: absolute;

                    width: 95%;
                    text-align: center;

                    color: rgb(235,230,230);
                    z-index: 10!important;
                    font-weight: bold">{{
                          isDeluxUser ?  bagJs.userGameLevel : `普通版 ${bagJs.userGameLevel}`
                        }}
                      </div>
                      <img :src="`/txz_image/${item.img_id}.png`" :title="item.season_name" v-if="item.id==999"
                           :style="{filter:useColorFul ? `grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})` : ''}"
                           :alt="item.item_name" width="100%" height="auto">
                    </div>

                  </a-popconfirm>
                </div>
              </hhh>

              <transition v-for="i in product" :key="i" name="animate__animated animate__bounce"
                          leave-active-class="animate__slideOutRight"
                          enter-active-class="animate__slideInDown" appear>

                <div id="border" :class="{'border_btm':!isFly}"
                     style="width: 100%;position: relative;z-index: 0">
                  <div>
                    <div
                        style="position: absolute;left: -45px;bottom: 0;top:0;width:40px;border: 1px solid rgb(78,109,190);border-radius: 9px">
                      <div
                          style="height: 100%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">

                        <a-tooltip placement="right">
                          <template slot="title">
                            置顶该商品栏
                          </template>
                          <a-icon type="vertical-align-top" v-show="i != 1" style="font-size: 25px;color: aqua"
                                  @click="pro_top(i)"/>
                        </a-tooltip>


                        <a-tooltip placement="right">
                          <template slot="title">
                            与上一商品行交换
                          </template>
                          <a-icon type="up-circle" v-show="i != 1" style="font-size: 25px;color: aqua"
                                  @click="pro_up(i)"/>
                        </a-tooltip>

                        <a-tooltip placement="right">
                          <template slot="title">
                            删除此行
                          </template>
                          <a-icon type="close-circle" style="font-size: 25px;color: aqua"
                                  @click="pro_close(i)"/>
                        </a-tooltip>


                        <a-tooltip placement="right">
                          <template slot="title">
                            与下一商品行交换
                          </template>
                          <a-icon v-show="i != product  " type="down-circle" style="font-size: 25px;color: aqua"
                                  @click="pro_down(i)"/>
                        </a-tooltip>

                      </div>
                    </div>

                    <!--                      showProductMessage-->
                    <div style="display:flex;justify-content: center">
                      <div style="text-align: center;font-weight: bold;color:#f3f3f3;width: 50%"
                           :style="{fontSize:titleDefaultSize+'px',fontFamily:seleted_font,color:titleBackground}"
                           v-if="!showTitleMsg" contenteditable="true">
                        {{ productList[i].title }}
                      </div>
                    </div>

                    <hhh group="gun"
                         style="display: flex;flex-wrap: nowrap;justify-content: center;align-items: center"
                         :class="{'product-medium':productMedium && !is1600,'product-large':productLarge && !is1600,'product-very-large':productVeryLarge && !is1600,
                         'product-large_1600':productLarge && is1600,'product-very-large_1600':productVeryLarge && is1600,
                            'product16':product16 && !is1600,'product18':product18 && !is1600,
                         'product16_1600':product16 && is1600,'product18_1600':product18 && is1600}"
                         :list="productList[i].items" @input="productAdd">

                      <div v-for="(item,r) in productList[i].items" style="position: relative" :key="item.classid"
                           :class="{'imgFor':itemSmall && !is1600,
                       'imgFor_large':itemLarge && !is1600,
                       'imgFor_very_large':itemVeryLarge && !is1600,
                       'imgFor_large_1600':itemLarge && is1600,
                       'imgFor_very_large_1600':itemVeryLarge && is1600,
                            'imgFor16':item16&& !is1600,
                       'imgFor18':item18 && !is1600,
                       'imgFor16_1600':item16 && is1600,
                        'imgFor18_1600':item18 && is1600}">

                        <!--                  等级-->
                        <div v-if="item.level != 0"
                             :class="{'level_small':itemSmall && !is1600,
                       'level_large':itemLarge && !is1600,
                       'level_very_large':itemVeryLarge && !is1600,
                       'level_large_1600':itemLarge && is1600,
                        'level_very_large_1600':itemVeryLarge && is1600,
                                                    'level16':item16&& !is1600,
                       'level18':item18 && !is1600,
                       'level16_1600':item16 && is1600,
                        'level18_1600':item18 && is1600}"

                             style="position: absolute;top: 10%;right: 10%;background-repeat: no-repeat;background-size:cover;z-index: 10!important;"
                             :style="{backgroundImage:`url('${require('@/assets/level.png')}')`}">
                          <div style="width: 90%">
                            <p style="float: right;color: whitesmoke;">{{ item.level }}</p>
                          </div>
                        </div>

                        <a-popover trigger="click">
                          <template slot="content">
                            <a-button type="danger" style="width: 100%;"
                                      @click="deleteItemP(i,r)">
                              删除
                            </a-button>
                            <div style="height: 5px"></div>

                            <a-button type="primary" style="width: 100%;"
                                      @click="itemBorderProduct(fork,i,r,item,item.isTurnBody)">描边/取消描边
                            </a-button>
                            <div style="width: 250px"></div>

                            <div style="height: 5px"></div>
                            <a-popconfirm placement="right" ok-text="确定" cancel-text="取消"
                                          @confirm="submitTextP(item,r,i)">
                              <template slot="title">
                                <a-input type="text" placeholder="你的描述" v-model="say"/>
                              </template>
                              <a-icon slot="icon" type="message" style="color: blue"/>
                              <a-button type="primary" style="width: 100%;">添加描述
                              </a-button>
                            </a-popconfirm>

                            <div style="height: 5px"></div>
                            <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
                              <div style="line-height: 31px;font-size: 16px;font-weight:600;color: red">武器等级:</div>
                              <a-select style="width: 65%;color: red;" v-model="item.level" @change="levelChangeProduct($event,item,i,r)">
                                <a-select-option :value="item.number" v-for="(item,i) in level_list" :key="i">
                                  {{ item.level }}
                                </a-select-option>
                              </a-select>
                            </div>
                          </template>
                          <img :src="item.icon_url" :title="item.name" :alt="item.classid"
                               width="100%"
                               height="auto"
                               :style="{filter:useColorFul ? `grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})` : ''}"
                               @click="forkPath">
                        </a-popover>
                        <div
                            style="width: 100%;text-align: center;font-size: 18px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                            :style="{color:describeBackground,fontSize: sayFs + 'px',fontWeight:'750',fontFamily:seleted_font}">
                          {{ item.describe ? item.describe : '' }}
                        </div>
                      </div>
                    </hhh>


                  </div>
                </div>
              </transition>


              <hhh group="gun" :list="bagJs.result" style="display: flex;flex-wrap: wrap;">
                <div v-for="(item,i) in bagJs.result" :key="item.classid" style="position: relative"
                     :class="{'imgFor':itemSmall && !is1600,
                       'imgFor_large':itemLarge && !is1600,
                       'imgFor_very_large':itemVeryLarge && !is1600,
                       'imgFor_large_1600':itemLarge && is1600,
                        'imgFor_very_large_1600':itemVeryLarge && is1600,
                             'imgFor16':item16&& !is1600,
                       'imgFor18':item18 && !is1600,
                       'imgFor16_1600':item16 && is1600,
                        'imgFor18_1600':item18 && is1600}">

                  <!--                  等级-->
                  <div v-if="item.level != 0"
                       :class="{'level_small':itemSmall && !is1600,
                       'level_large':itemLarge && !is1600,
                       'level_very_large':itemVeryLarge && !is1600,
                       'level_large_1600':itemLarge && is1600,
                        'level_very_large_1600':itemVeryLarge && is1600,
                                                    'level16':item16&& !is1600,
                       'level18':item18 && !is1600,
                       'level16_1600':item16 && is1600,
                        'level18_1600':item18 && is1600}"

                       style="position: absolute;top: 10%;right: 10%;background-repeat: no-repeat;background-size:cover;z-index: 10!important;"
                       :style="{backgroundImage:`url('${require('@/assets/level.png')}')`}">
                    <div style="width: 90%">
                      <p style="float: right;color: whitesmoke;">{{ item.level }}</p>
                    </div>
                  </div>


                  <a-popover trigger="click">
                    <template slot="content">
                      <div style="width: 250px"></div>
                      <a-button type="danger" style="width: 100%;" @click="deleteItem(i)">删除
                      </a-button>
                      <div style="height: 5px"></div>
                      <a-button type="primary" style="width: 100%;" @click="inTop(i)">置顶
                      </a-button>
                      <div style="height: 5px"></div>

                      <a-button type="primary" style="width: 100%;"
                                @click="itemBorderBody(fork,i,item.isTurnBody,item)">
                        描边/取消描边
                      </a-button>
                      <div style="height: 5px"></div>

                      <a-popconfirm placement="right" ok-text="确定" cancel-text="取消"
                                    @confirm="submitText(item,i)">
                        <template slot="title">
                          <a-input type="text" placeholder="你的描述" v-model="say"/>
                        </template>
                        <a-icon slot="icon" type="message" style="color: blue"/>

                        <a-button type="primary" style="width: 100%;">
                          添加描述
                        </a-button>
                      </a-popconfirm>

                      <div style="height: 5px"></div>
                      <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
                        <div style="line-height: 31px;font-size: 16px;font-weight:600;color: red">武器等级:</div>
                        <a-select style="width: 65%;color: red;" v-model="item.level" @change="levelChangeProduct($event,item)">
                          <a-select-option :value="item.number" v-for="(item,i) in level_list" :key="i">
                            {{ item.level }}
                          </a-select-option>
                        </a-select>
                      </div>
                    </template>
                    <img :src="item.icon_url" :title="item.name" :alt="item.classid"
                         width="100%"
                         :style="{filter:useColorFul ? `grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})` : ''}"
                         height="auto" @click="forkPath">
                  </a-popover>
                  <div
                      style="width: 100%;text-align: center;font-size: 18px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                      :style="{color:describeBackground,fontSize: sayFs + 'px',fontWeight:'750',fontFamily:seleted_font}">
                    {{ item.describe ? item.describe : '' }}
                  </div>
                </div>

              </hhh>

              <!--              <hhh group="gun" :list="bagJs.smallresult">-->
              <!--                <div v-for="item in bagJs.smallresult" :key="item.classid"-->
              <!--                     :class="{'imgFor-it':!is1600,'imgFor-it_1600':is1600}" id="small">-->
              <!--                  <img :src="item.icon_url"-->
              <!--                       :style="{filter:useColorFul ? `grayscale(${filterValue.grayscale/100}) blur(${filterValue.blur}px) sepia(${filterValue.sepia/100}) saturate(${filterValue.saturate/100}) opacity(${filterValue.opacity/100}) contrast(${filterValue.contrast/100}) brightness(${filterValue.brightness/100}) hue-rotate(${filterValue.hueRotate/100}deg) invert(${filterValue.invert/100})` : ''}"-->
              <!--                       :title="item.name" :alt="item.classid" width="100%"-->
              <!--                       height="auto">-->
              <!--                </div>-->
              <!--              </hhh>-->
            </div>

          </div>
        </div>

      </div>
    </div>

    <div
        style="position:fixed;right: 30px;bottom: 25px;border: 2px solid rgb(19,30,144);width: 70px;height: 130px;border-radius: 15px;display: flex;flex-wrap: wrap;justify-content: center;align-items: center">
      <a-icon type="up-circle" style="font-size: 45px;color: rgba(212, 98, 0, 1);cursor:pointer"
              @click="local_href(1)"/>
      <a-icon type="down-circle" style="font-size: 45px;color: rgba(212, 98, 0, 1);cursor:pointer"
              @click="local_href(2)"/>
    </div>

    <div class="getWidth" v-show="!isFly" style="margin-bottom: 40px">
    </div>
    <div style="color:rgb(79,41,45);" v-show="!isFly"></div>


  </div>
</template>


<script>
import hhh from 'vuedraggable'
import html2canvas from '@/plugin/html2canvas-fixed'
import ColorPicker from 'vue2-color-picker-gradient'
import $ from 'jquery'
import {saveAs} from 'file-saver'
import {mapState} from "vuex";
import {nanoid} from "nanoid";

const key = '123'
export default {
  components: {
    hhh,

    // eslint-disable-next-line vue/no-unused-components
    ColorPicker
  },
  data() {
    return {
      menuStatus: {
        menu_all: true,
        menu_download: false
      },
      seleted_font: 'Microsoft YaHei',
      level_list: [
        {
          number: 0,
          level: '无等级',
        },
        {
          number: 1,
          level: '1级',
        },
        {
          number: 2,
          level: '2级',
        },
        {
          number: 3,
          level: '3级',
        },
        {
          number: 4,
          level: '4级',
        },
        {
          number: 5,
          level: '5级',
        },
        {
          number: 6,
          level: '6级',
        },
        {
          number: 7,
          level: '7级',
        },
        {
          number: 8,
          level: '8级',
        },
        {
          number: 9,
          level: '9级',
        },
        {
          number: 10,
          level: '10级',
        },


      ],
      font_list: [
        {
          name: '微软雅黑',
          face: 'Microsoft YaHei'
        },
        {
          name: '楷体',
          face: 'KaiTi'
        },
        {
          name: '隶书',
          face: 'LiSu'
        },
        {
          name: '幼圆',
          face: 'YouYuan'
        },
        {
          name: '华文细黑',
          face: 'STXihei'
        },
        {
          name: '华文楷体',
          face: 'STKaiti'
        },
        {
          name: '华文宋体',
          face: 'STSong'
        },
        {
          name: '华文中宋',
          face: 'STZhongsong'
        },
        {
          name: '华文仿宋',
          face: 'STFangsong'
        },
        {
          name: '方正舒体',
          face: 'FZShuTi'
        },

        {
          name: '方正姚体',
          face: 'FZYaoti'
        },
        {
          name: '华文彩云',
          face: 'STCaiyun'
        },
        {
          name: '华文琥珀',
          face: 'STHupo'
        },
        {
          name: '华文隶书',
          face: 'STLiti'
        },
        {
          name: '华文行楷',
          face: 'STXingkai'
        }, {
          name: '华文新魏',
          face: 'STXinwei'
        },
      ],
      titleDefaultSize: 30,
      is1600: false,
      selectSize: 1,
      isDeluxUser: false,

      Phone: false,

      isFly: false,
      titleBackground: 'rgba(255,255,255,1)',
      itemSmall: true,
      itemLarge: false,
      itemVeryLarge: false,
      item16: false,
      item18: false,
      seasonTickLarge: false,
      productMedium: true,
      productLarge: false,
      productVeryLarge: false,
      product16: false,
      product18: false,
      showMenu: true,
      controlOnStart: true,
      fork: '',
      productList: [
        {title: '', items: [], showTitle: true}
      ],
      cacheList: [],

      showTitleMsg: false,
      top: 10,
      fs: '40px',
      show: '',
      sss: [],
      say: '',
      topTips: '',

      topTipsBackground: 'linear-gradient(90deg, rgba(50, 182, 119, 1) 24%,rgba(235, 212, 26, 1) 48%,rgba(243, 116, 6, 1) 72%)',
      describeBackground: 'rgba(255, 255, 255, 1)',
      itemBorderBackground: 'linear-gradient(90deg, rgba(248, 204, 9, 1) 0%,rgba(248, 204, 9, 1) 100%)',

      topTipsColors: [
        {
          color: 'rgba(243, 116, 6, 1)',
          hex: '#F37406',
          rgba: {r: 243, g: 116, b: 6, a: 1},
          pst: 72,
        },
        {
          color: 'rgba(235, 212, 26, 1)',
          hex: '#EBD41A',
          rgba: {r: 235, g: 212, b: 26, a: 1},
          pst: 48,
        },
        {
          color: 'rgba(50, 182, 119, 1)',
          hex: '#32B677',
          rgba: {r: 50, g: 182, b: 119, a: 1},
          pst: 24,
        },

      ],

      itemBorderColors: [
        {
          color: 'rgba(248, 204, 9, 1)',
          hex: '#f8cc09',
          rgba: {r: 248, g: 204, b: 9, a: 1},
          pst: 100,
        },
        {
          color: 'rgba(248, 204, 9, 1)',
          hex: '#f8cc09',
          rgba: {r: 248, g: 204, b: 9, a: 1},
          pst: 0,
        },
      ],

      itemBorderEvent: {
        deg: 90,
        colors: [
          {
            color: 'rgba(248, 204, 9, 1)',
            hex: '#f8cc09',
            rgba: {r: 248, g: 204, b: 9, a: 1},
            pst: 100,
          },
          {
            color: 'rgba(248, 204, 9, 1)',
            hex: '#f8cc09',
            rgba: {r: 248, g: 204, b: 9, a: 1},
            pst: 0,
          },
        ]
      },


      describeColor: {
        hex: '#ffffff',
        hex8: '#ffffff',
        rgba: {r: 255, g: 255, b: 255, a: 1},
        a: 1,
        color: 'rgba(255,255,255,1)',
      },


      seasonShow: [],
      bgWriten: 25,
      sayFs: 20,
      inputbg: require('@/assets/input_bg.jpg'),
      bagJs: '',
      defaultbg: require('@/assets/privacy_bg.png'),
      pic: '',
      base64: [],
      blob: '',
      isPay: sessionStorage.getItem('isPay'),
      headBg: '',
      product: 0,


      bgArr: [
        require('@/assets/localside.jpg'),
        require('@/assets/inside.jpg'),
        require('@/assets/bg3.jpeg'),
        require('@/assets/bg5.jpeg'),
        require('@/assets/bg4.jpeg'),
      ],
      link: '',


      domSplit: 0,
      domHeight: 0,
      domWidth: 0,

      domStart: 0,
      domStack: 0,
      loadingTime: 5,
      downloading: 0,
      local_data: [],
      headBg_list: [],
      startDownload: false,
      startDownloading: '',
      proportion: '',
      loaderBase64: '',
      timeoutInterval: {},
      timeoutSec: 0,
      blocked: false,
      is_black: false,

      dataUrl: '',
      imgTemp: false,
      isOpen: '打开暂存区',

      isMaterial: '打开素材库',
      isOpenMaterial: false,
      linearCont: [0, 0, 170, 0],

      bgColor: '',
      useColorFul: false,

      filterValue: {//滤镜类型
        "grayscale": 0,//1,灰度
        "blur": 0, //2,高斯模糊
        "sepia": 20,//3,深褐色
        "saturate": 200,//4,饱和度
        "opacity": 100, //5,透明度
        "brightness": 100,//6,亮暗度
        "contrast": 100,//7,对比度
        "hueRotate": 0,//8,色相旋转
        "invert": 0,//9,反转
      },

      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      errorImg: require('@/assets/selectCurr.png')
    }
  },
  methods: {
    colorChange(e, type) {
      switch (type) {
        case 'grayscale':
          this.filterValue.grayscale = e
          break
        case 'blur':
          this.filterValue.blur = e;
          break
        case 'sepia':
          this.filterValue.sepia = e;
          break
        case 'saturate':
          this.filterValue.saturate = e;
          break
        case 'opacity':
          this.filterValue.opacity = e;
          break
        case 'brightness':
          this.filterValue.brightness = e;
          break
        case 'contrast':
          this.filterValue.contrast = e;
          break
        case 'hueRotate':
          this.filterValue.hueRotate = e;
          break
        case 'invert':
          this.filterValue.invert = e;
          break

      }
    },

    titleColorful({color: {color}}) {
      this.titleBackground = color
    },

    useColorFilter(v) {
      this.useColorFul = v
      this.backupMethod()
    },

    sliderEnded(v) {
      this.titleDefaultSize = v
    },
    topTipsColorful({style}) {
      this.topTipsBackground = style.split(";")[0]
    },
    local_href(v) {
      if (v == 1) {
        document.documentElement.scrollTop = 0
      } else {
        document.documentElement.scrollTop = $('.download').height()
      }
    },
    describeColorful({color: {color}}) {
      this.describeBackground = color
    },
    itemBorderColorful(e) {
      this.itemBorderEvent = e
      this.itemBorderBackground = e.style.split(";")[0]
    },
    productAdd(e) {
      console.log(e)
    },
    pro_up(v) {
      if (v == 1) {
        this.$message.error({content: '该商品行为第一行，无法执行此操作', key, duration: 2})
        return
      }

      const cache = this.productList[v - 1]
      this.$set(this.productList, v - 1, this.productList[v])
      this.$set(this.productList, v, cache)

    },

    pro_top(v) {
      if (v == 1) {
        this.$message.error({content: '该商品行为第一行，无法执行此操作', key, duration: 2})
        return
      }


      const cache = this.productList[v]
      this.productList.splice(v, 1)
      this.productList.splice(0, 1)
      this.productList.unshift(cache)
      this.productList.unshift({})

      // //
      // this.$set(this.productList, 1, this.productList[v])
      // this.$set(this.productList, v, cache)
      this.backupMethod()

    },
    pro_close(i) {
      const that = this
      this.$confirm({
        title: '确定要删除这条商品栏?',
        content: <div style="color:red;">被删除商品行上的物品会被释放回物品栏，请确认后再删除</div>,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          for (const v of that.productList[i]) {
            that.bagJs.result.unshift(v)
          }
          that.deleteProduct(i)

        },
        onCancel() {
          // console.log('');
        },
        class: 'test',
      });
    },
    pro_down(v) {
      if (this.product == v) {
        this.$message.error({content: '该商品行为最后一行，无法执行此操作', key, duration: 2})
        return
      }

      const cache = this.productList[v + 1]
      this.$set(this.productList, v + 1, this.productList[v])
      this.$set(this.productList, v, cache)


    },
    deleteHeadBg(v) {
      this.$message.success({content: '已删除背景', key})
      this.headBg_list = this.headBg_list.filter((item, index) => index != v)
    },
    go_help() {
      location.href = '/ArticleView/40.html'
    },
    setSize(id) {
      switch (id) {
        case 1:
          this.selectSize = 1
          this.itemSmall = true
          this.itemLarge = false
          this.itemVeryLarge = false
          this.seasonTickLarge = false
          this.productMedium = true
          this.productLarge = false
          this.productVeryLarge = false
          this.product16 = false
          this.product18 = false
          this.item16 = false
          this.item18 = false
          break
        case 2:
          this.selectSize = 2
          this.itemSmall = false
          this.itemLarge = true
          this.itemVeryLarge = false
          this.seasonTickLarge = true
          this.productMedium = false
          this.productLarge = true
          this.productVeryLarge = false
          this.product16 = false
          this.product18 = false
          this.item16 = false
          this.item18 = false
          break
        case 3:
          this.selectSize = 3
          this.itemSmall = false
          this.itemLarge = false
          this.itemVeryLarge = true
          this.seasonTickLarge = true
          this.productMedium = false
          this.productLarge = false
          this.productVeryLarge = true
          this.product16 = false
          this.product18 = false
          this.item16 = false
          this.item18 = false
          break

        case 4:
          this.selectSize = 4
          this.itemSmall = false
          this.itemLarge = false
          this.itemVeryLarge = false
          this.item16 = true
          this.item18 = false
          this.seasonTickLarge = true
          this.productMedium = false
          this.productLarge = false
          this.productVeryLarge = false
          this.product16 = true
          this.product18 = false
          break
        case 5:
          this.selectSize = 5
          this.itemSmall = false
          this.itemLarge = false
          this.itemVeryLarge = false
          this.item16 = false
          this.item18 = true
          this.seasonTickLarge = true
          this.productMedium = false
          this.productLarge = false
          this.productVeryLarge = false
          this.product16 = false
          this.product18 = true
          break
      }

      this.$forceUpdate()
    },


    forkPath(e) {
      this.fork = e
    },
    //物品描边
    itemBorderBody({target}, i, isTurnBody, item) {
      if (!isTurnBody) {
        this.$message.success({content: '已描边,如果移动物品到商品栏或暂存栏请重新描边', key, duration: 1})

        this.$set(item, 'isTurnBody', true)
        this.$set(this.bagJs.result, i, item)
        let canvas;
        let context
        canvas = document.createElement('canvas');
        const img = new Image();
        img.crossOrigin = 'Anonymous';  //图片转canvas
        img.src = target.currentSrc;
        img.width = '250'
        img.height = '250'
        canvas.width = 250;
        canvas.height = 260;
        const that = this;
        img.onload = function () {

          // const g = Math.abs(250 * Math.cos(that.itemBorderEvent.deg)) + Math.abs(250 * Math.sin(that.itemBorderEvent.deg))
          // const h = (Math.abs(225 * Math.cos(that.itemBorderEvent.deg)) + Math.abs(235 * Math.sin(that.itemBorderEvent.deg))) / 2
          //
          // const centerx = that.itemBorderEvent.deg + h / 2
          // const centery = Math.tan(centerx)
          // console.log(centerx, centery)
          // const sx = centerx - Math.cos(that.itemBorderEvent.deg) * h
          // const sy = centery + Math.sin(that.itemBorderEvent.deg) * h
          //
          // const ex = centerx + Math.cos(that.itemBorderEvent.deg) * h
          // const ey = centery - Math.sin(that.itemBorderEvent.deg) * h

          context.drawImage(this, 0, 0, 225, 235);
          context.globalCompositeOperation = "source-in";
          if (that.itemBorderEvent.deg == 0) {
            that.linearCont = [170, 0, 0, 0]
          } else if (that.itemBorderEvent.deg > 1 && that.itemBorderEvent.deg < 170) {
            that.linearCont = [0, 0, 170, 0]
          } else if (that.itemBorderEvent.deg > 170 && that.itemBorderEvent.deg < 180) {
            that.linearCont = [0, 0, 0, 170]
          } else if (that.itemBorderEvent.deg > 180 && that.itemBorderEvent.deg < 340) {
            that.linearCont = [0, 170, 0, 0]
          } else if (that.itemBorderEvent.deg > 340) {
            that.linearCont = [170, 0, 0, 0]
          }

          // const linearGradient = context.createLinearGradient(sx, sy, ex, ey)
          // if()
          const linearGradient = context.createLinearGradient(that.linearCont[0], that.linearCont[1], that.linearCont[2], that.linearCont[3])

          for (const color of that.itemBorderEvent.colors) {
            linearGradient.addColorStop((color.pst / 100), color.hex)
          }

          context.fillStyle = linearGradient;


          context.fillRect(0, 0, this.width + 20, this.height + 20);
          let img = document.createElement("img")
          img.src = canvas.toDataURL("image/png", 1)
          target.style.setProperty('background', `url('${img.src}')`)
          target.style.setProperty('background-size', '117%')
          target.style.setProperty('background-position-x', '15%')
          target.style.setProperty('background-position-y', '25%')

        };
        context = canvas.getContext("2d");
      } else {
        this.$set(item, 'isTurnBody', false)
        this.$set(this.bagJs.result, i, item)
        target.style.setProperty('background', `url('')`)
      }
    },
    //商品行物品描边
    itemBorderProduct({target}, i, r, item, isTurnBody) {
      if (!isTurnBody) {
        this.$message.success({content: '已描边,如果移动物品到商品栏或暂存栏请重新描边', key, duration: 1})
        this.$set(item, 'isTurnBody', true)
        this.$set(this.productList[i], r, item)
        let canvas;
        let context
        canvas = document.createElement('canvas');
        const img = new Image();
        img.crossOrigin = 'Anonymous';  //图片转canvas
        img.src = target.currentSrc;
        img.width = '250'
        img.height = '250'
        canvas.width = 250;
        canvas.height = 260;
        const that = this;
        img.onload = function () {
          context.drawImage(this, 0, 0, 225, 235);
          context.globalCompositeOperation = "source-in";


          // const g = Math.abs(250 * Math.cos(that.itemBorderEvent.deg)) + Math.abs(250 * Math.sin(that.itemBorderEvent.deg))
          // const h = (Math.abs(250 * Math.cos(that.itemBorderEvent.deg)) + Math.abs(250 * Math.sin(that.itemBorderEvent.deg))) / 2
          // const centerx = that.itemBorderEvent.deg / 1000 + 250 / 2
          // const centery = that.itemBorderEvent.deg / 1000 + 250 / 2
          //
          // const sx = centerx - Math.cos(that.itemBorderEvent.deg) * h
          // const sy = centery + Math.sin(that.itemBorderEvent.deg) * h
          //
          // const ex = centerx + Math.cos(that.itemBorderEvent.deg) * h
          // const ey = centery - Math.sin(that.itemBorderEvent.deg) * h
          //
          // context.drawImage(this, 0, 0, 225, 235);
          // context.globalCompositeOperation = "source-in";
          //
          // const linearGradient = context.createLinearGradient(ex, ey, sx, sy)

          context.drawImage(this, 0, 0, 225, 235);
          context.globalCompositeOperation = "source-in";
          if (that.itemBorderEvent.deg == 0) {
            that.linearCont = [170, 0, 0, 0]
          } else if (that.itemBorderEvent.deg > 1 && that.itemBorderEvent.deg < 170) {
            that.linearCont = [0, 0, 170, 0]
          } else if (that.itemBorderEvent.deg > 170 && that.itemBorderEvent.deg < 180) {
            that.linearCont = [0, 0, 0, 170]
          } else if (that.itemBorderEvent.deg > 180 && that.itemBorderEvent.deg < 340) {
            that.linearCont = [0, 170, 0, 0]
          } else if (that.itemBorderEvent.deg > 340) {
            that.linearCont = [170, 0, 0, 0]
          }

          // const linearGradient = context.createLinearGradient(sx, sy, ex, ey)
          // if()
          const linearGradient = context.createLinearGradient(that.linearCont[0], that.linearCont[1], that.linearCont[2], that.linearCont[3])

          for (const color of that.itemBorderEvent.colors) {
            linearGradient.addColorStop((color.pst / 100), color.hex)
          }

          context.fillStyle = linearGradient;


          context.fillRect(0, 0, this.width + 20, this.height + 20);
          let img = document.createElement("img")
          img.src = canvas.toDataURL("image/png", 1)
          target.style.setProperty('background', `url('${img.src}')`)
          target.style.setProperty('background-size', '118%')
          target.style.setProperty('background-position-x', '18%')
          target.style.setProperty('background-position-y', '25%')
        };
        context = canvas.getContext("2d");
      } else {
        this.$set(item, 'isTurnBody', false)
        this.$set(this.productList[i], r, item)
        target.style.setProperty('background', `url('')`)
      }
    },
    cacheAdd({item: {style}}) {
      style.width = '60px'
      style.height = '60px'
    },
    // eslint-disable-next-line no-unused-vars
    log(e) {
      // console.log(e)
    },
    // eslint-disable-next-line no-unused-vars
    clone(e) {
      // console.log(e, 'clone')
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({originalEvent}) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    openTemp() {
      if (this.isOpenMaterial) {
        this.isOpenMaterial = false
        this.isMaterial = '打开素材库'
      }
      this.imgTemp = !this.imgTemp
      this.isOpen = this.imgTemp ? '关闭暂存区' : '打开暂存区'

    },
    openMaterial() {
      if (this.imgTemp) {
        this.imgTemp = false
        this.isOpen = this.imgTemp ? '关闭暂存区' : '打开暂存区'
      }
      this.isOpenMaterial = !this.isOpenMaterial
      this.isMaterial = this.isOpenMaterial ? '关闭素材库' : '打开素材库'
    },
    guanggao() {
      const a = document.createElement('a')
      a.href = this.website_info.website_info[0].adv_url
      // alink.download = steamid
      a.click()
    },
    submitText(v, r) {
      this.$set(v, 'describe', this.say)
      this.$set(this.bagJs.result, r, v)
      this.say = ''
    },
    deleteItem(v) {
      this.bagJs.result.splice(v, 1)
    },
    submitTextP(v, r, i) {
      this.$set(v, 'describe', this.say)
      this.$set(this.productList[i], r, v)
      // console.log(this.productText[v])
      this.say = ''
    },
    deleteItemP(v, x) {
      this.bagJs.result.unshift(this.productList[v].items[x])
      this.productList[v].items.splice(x, 1)
    },
    inTop(v) {
      this.bagJs.result.unshift(this.bagJs.result[v])
      this.bagJs.result.splice(v + 1, 1)
    },

    async customGuns() {
      this.$message.loading({content: '放置中...', key: 'loading', duration: 0})
      const {data: {data}} = (await this.$axios.post('/Api/Image/GetSuitSorting'))
      const fill = JSON.parse(data)
      console.log(fill)

      // const isIncludes = (v) => {
      //   const temp = this.bagJs.result.find(key => key.name == v)
      //   return temp != undefined
      // }


      fill.forEach(col => {
        let isAdd = true
        const temp = []
        const {title, items} = col
        if (items.length) {
          items.forEach(key => {

            if ((this.bagJs.result.find(keys => keys.name == key)) != undefined) {
              if (isAdd) {
                this.product += 1
                isAdd = false
              }
              temp.push(this.bagJs.result.find(keys => keys.name == key))
              this.bagJs.result = this.bagJs.result.filter(keys => keys.name != key)
            }
          })

          if (temp.length) {
            this.productList.push({title: title, items: temp, showTitle: true})
          }
        }

      })
      this.$message.success({content: '放置完成', key: 'loading', duration: 1})


    },
    addProduct() {
      if (this.isPay == 0 || this.isPay == undefined || this.isPay == null || this.isPay == false) {
        this.$message.warning('购买次数后方可使用此功能', 10)
        return
      }
      this.productList.push({title: '商品行', items: [], showTitle: true})
      this.product += 1
      this.productList.push()
    },
    deleteProduct(i) {
      if (this.isPay == 0 || this.isPay == undefined || this.isPay == null || this.isPay == false) {
        this.$message.warning('购买次数后方可使用此功能', 10)
        return
      }
      // console.log(this.product)
      if (this.product <= 0) {
        // console.log(i)
      } else {


        // for (let r = 1; r <= this.product; r++) {
        //   if (r !== i) {
        //
        //   }
        //   // console.log(r)
        // }
        this.$nextTick(() => {
          this.product -= 1
        })
        this.productList.splice(i, 1)

        // this.productList[i] = this.productList[this.product]
        // this.productList[this.product] = []
        // this.productText[i] = this.productText[this.product]
        // this.productText[this.product] = []


      }
    },

    deleteSeasonTicket(v) {
      this.seasonShow[v] = this.$set(this.seasonShow, v, true)
    },
    randomBg() {
      this.inputbg = this.bgArr[parseInt(Math.random() * 5)]
    },
    customBg(e) {
      if (this.isPay == 0 || this.isPay == undefined || this.isPay == null || this.isPay == false) {
        this.$message.warning('购买次数后方可使用此功能', 10)
      } else {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.inputbg = reader.result
        }
      }

    },
    customHeadBg(e) {
      if (this.isPay == 0 || this.isPay == undefined || this.isPay == null || this.isPay == false) {
        this.$message.warning('购买次数后方可使用此功能', 10)
      } else {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.headBg_list.unshift(reader.result)
        }
      }
    },

    async levelChange(e, item, index) {
      try {
        clearTimeout(this.throttleTimeout[index]);
        this.throttleTimeout[index] = setTimeout(async () => {
          await this.$axios.post('/Api/setLevel', {
            steamid: sessionStorage.getItem('steamId'),
            name: item.icon_url.split('/').reverse()[0].split('.')[0],
            level: e
          });
        }, 3000)
      } catch (e) {
        console.log(e)
      }
      this.backupMethod()
    },

    async levelChangeProduct(e, item, rowIndex, itemIndex) {
      console.log(e, item)
      try {
        clearTimeout(this.throttleTimeoutProduct[rowIndex][itemIndex]);
        this.throttleTimeoutProduct[rowIndex][itemIndex] = setTimeout(async () => {
          await this.$axios.post('/Api/setLevel', {
            steamid: sessionStorage.getItem('steamId'),
            name: item.icon_url.split('/').reverse()[0].split('.')[0],
            level: e
          });
        }, 3000)
      } catch (e) {
        console.log(e)
      }

      this.backupMethod()
    },


    //本地下载
    async local_download(v) {
      this.startDownloading = '正在生成...'
      this.downloading = 0

      this.startDownload = true
      this.isFly = true

      if (this.selectSize > 1) {
        this.is1600 = true
      }

      this.$nextTick(() => {
        setTimeout(async () => {
          const element = this.$refs.download
          const domHeight = element.offsetHeight
          const domWidth = element.offsetWidth

          console.log(domHeight, domWidth)

          this.domSplit = domHeight / 5
          this.domWidth = domWidth
          this.domHeight = this.domSplit
          this.domStart = 0
          this.loadingTime = 5
          this.domStack = 1
          this.dataUrl = ''

          this.local_data = []
          this.proportion = 10
          this.loaderBase64 = ''


          if (domHeight > 65535) {
            for (let i = 10; i >= 1; i -= 0.01) {
              if (parseFloat((domHeight * i) / 10) < 65535) {
                this.proportion = i
                i = -1;
              }
            }
          }

          this.proportion = parseFloat(this.proportion / 10)

          this.$message.loading({content: '正在校验下载请求...', key, duration: 0})

          await this.$axios.post('/Api/Image/inquiryPaymentStatus', {steamid: sessionStorage.getItem('steamId')}).then(({data: {code}}) => this.isPay = code).catch(() => this.isPay = 0)

          if (this.isPay != sessionStorage.getItem('isPay')) {
            this.$message.error({content: '校验未通过', key, duration: 0})
            setTimeout(() => {
              this.$router.replace('/getsteamid')
            }, 300)
            return
          }


          this.$message.success({content: '校验通过,开始下载...', key, duration: 2})


          //40s超时
          this.timeoutInterval = setInterval(() => {
            ++this.timeoutSec
          }, 1000)


          if (v === 1) {
            this.$message.success('使用本地下载原图,速度和系统配置挂钩', 2)
            this.local_download_large(v, element)
          } else if (v === 2) {
            this.$message.success('使用本地下载压缩,速度和系统配置挂钩', 2)
            this.local_download_small(element)
          } else if (v === 3) {
            this.$message.success('使用本地下载超清原图,速度和系统配置挂钩', 2)
            this.local_download_large(v, element)
          }

        }, 5000)
      })
    },


    local_download_large(v, element) {

      console.log(this.domStack)
      html2canvas(element, {
        width: this.domWidth, //dom 原始宽度
        // height: dom.clientHeight,
        useCORS: true,
        height: this.domHeight,
        backgroundColor: 'none',
        imageTimeout: 0,
        y: this.domStart,
        x: 0,
        scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
        scrollX: 0,
        scale: this.proportion,
        allowTaint: true,
      }).then(canvas => {
        this.local_data.push(canvas.toDataURL("image/png"))
        this.downloading = Math.round((this.domStack / this.loadingTime) * 100)
        if (this.domStack === 5) {
          this.downloadImgs(this.local_data, v)
        } else {
          this.domStack++
          // console.log(this.domStack)
          this.domStart += this.domSplit
          this.local_download_large(v, element)
        }
      })
    },

    local_download_small(element) {

      console.log(this.domStack)
      html2canvas(element, {
        width: this.domWidth, //dom 原始宽度
        // height: dom.clientHeight,
        useCORS: true,
        height: this.domHeight,
        backgroundColor: 'none',
        imageTimeout: 0,
        y: this.domStart,
        x: 0,
        scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
        scrollX: 0,
        scale: this.proportion,
        allowTaint: true,
        dpi: window.devicePixelRatio,

      }).then(canvas => {
        this.local_data.push(canvas.toDataURL("image/png"))
        this.downloading = Math.round((this.domStack / this.loadingTime) * 100)
        if (this.domStack === 5) {
          this.downloadImgs(this.local_data, 2)
        } else {
          this.domStack++
          this.domStart += this.domSplit
          this.local_download_small(element)
        }
      })
    },


    async downloadImgs(v, r) {
      this.startDownloading = '已完成,等待下载'
      await this.mergeImgs(v, parseFloat(this.domWidth * this.proportion), parseFloat(this.domHeight * this.proportion), r).then(val => {
        this.dataUrl += val
      })

      this.isFly = false
      this.is1600 = false
      clearInterval(this.timeoutInterval)
      this.timeoutSec = 0


      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() - 1
      const day = date.getDay()
      const hour = date.getHours()
      const min = date.getMinutes()
      const sec = date.getSeconds()

      const url = await this.$base64ToBlob(this.dataUrl)

      saveAs(url, `${sessionStorage.getItem('steamId')}___${year}年${month}月${day}日---${hour}时${min}分${sec}秒.png`)
      // const a = document.createElement('a')
      // a.href = URL.createObjectURL(url)
      // a.download = `${sessionStorage.getItem('steamId')}___${year}年${month}月${day}日---${hour}时${min}分${sec}秒.png`
      // a.click()
      this.startDownload = false
      this.$message.success({content: '已完成下载', key})
    },


    mergeImgs(list, cwidth, cheight, r) {
      return new Promise((resolve) => {
        const baseList = [];
        // 创建 canvas 节点并初始化
        const canvas = document.createElement('canvas');
        canvas.width = cwidth;
        canvas.height = cheight * list.length;
        const context = canvas.getContext('2d');
        list.map((item, index) => {
          const img = new Image();
          img.src = item;
          // 跨域
          img.style.background = 'none'
          img.crossOrigin = 'Anonymous';
          img.onload = () => {
            context.drawImage(img, 0, cheight * index, cwidth, cheight);

            if (r === 1) {
              this.loaderBase64 = canvas.toDataURL('image/jpeg', 1)
            } else if (r === 2) {
              this.loaderBase64 = canvas.toDataURL('image/jpeg', 0.92);
            } else if (r === 3) {
              this.loaderBase64 = canvas.toDataURL('image/png');
            }


            baseList.push(this.loaderBase64);
            if (baseList[list.length - 1]) {
              // 返回新的图片
              resolve(baseList[list.length - 1]);
            }
          };
        });
      });
    },


    //服务器下载
    async swithToImg(v) {
      this.startDownloading = '正在提交...'
      this.downloading = 0
      this.startDownload = true
      this.isFly = true

      if (this.selectSize > 1) {
        this.is1600 = true
      }

      this.$nextTick(() => {
        setTimeout(async () => {
          const element = this.$refs.download
          this.domSplit = element.offsetHeight / 5
          this.domWidth = element.offsetWidth
          this.domHeight = this.domSplit
          this.domStart = 0
          this.loadingTime = 5
          this.domStack = 1
          this.dataUrl = ''

          this.local_data = []

          await this.$axios.post('/Api/Image/inquiryPaymentStatus', {steamid: sessionStorage.getItem('steamId')}).then(({data: {code}}) => this.isPay = code).catch(() => this.isPay = 0)

          if (this.isPay != sessionStorage.getItem('isPay')) {
            this.$message.error({content: '校验未通过', key, duration: 0})
            setTimeout(() => {
              this.$router.replace('/getsteamid')
            }, 300)
            return
          }


          //40s超时
          this.timeoutInterval = setInterval(() => {
            ++this.timeoutSec
          }, 1000)

          if (v === 1) {
            this.$message.loading({
              content: '使用服务器生成图片，原图下载比较耗费时间，请耐心等待弹出下载',
              key,
              duration: 0
            })
            this.CopyImage(element)
          } else {
            this.$message.loading({content: '使用服务器生成图片，请耐心等待弹出下载', key, duration: 0})
            this.CopyImageSmall(element)
          }
        }, 5000)

      })

    },

    //压缩下载
    async CopyImageSmall(element) {
      console.log(this.domStack)
      await html2canvas(element, {
        width: this.domWidth,
        height: this.domHeight,
        backgroundColor: 'none',
        imageTimeout: 0,
        y: this.domStart,
        x: 0,
        scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
        scrollX: 0,
        scale: 0.6,
        allowTaint: true,
      }).then(async canvas => {
        this.pic = canvas.toDataURL("image/png", 0)
        this.base64.push(this.pic)

        this.$axios.post('/Api/Base64ToImage', {
          base64: this.pic,
          steamid: sessionStorage.getItem('steamId'),
          index: this.domStack
        }).then(() => {
          this.downloading = Math.round((this.domStack / this.loadingTime) * 100)

          if (this.domStack === 5) {
            this.getOver()
          } else {
            this.domStack++
            this.domStart += this.domSplit
            this.CopyImageSmall(element)
          }
        })
      });
    }
    ,


    getOver() {
      this.isFly = false
      this.is1600 = false
      this.startDownloading = '已提交,等待服务器响应'
      this.DownLoad(sessionStorage.getItem('steamId'))
    }
    ,

    //原图下载
    async CopyImage(element) {
      console.log(this.domStack)
      html2canvas(element, {
        width: this.domWidth,
        //width: dom.clientWidth, //dom 原始宽度
        // height: dom.clientHeight,
        height: this.domHeight,
        backgroundColor: 'none',
        imageTimeout: 0,
        y: this.domStart,
        x: 0,
        scrollY: 0, // html2canvas默认绘制视图内的页面，需要把scrollY，scrollX设置为0
        scrollX: 0,
        scale: 1,
        allowTaint: true,
      }).then(canvas => {
        this.pic = canvas.toDataURL("image/png", 0)
        this.base64.push(this.pic)
        this.$axios.post('/Api/Base64ToImage', {
          base64: this.pic,
          steamid: sessionStorage.getItem('steamId'),
          index: this.domStack
        }).then(() => {
          this.downloading = Math.round((this.domStack / this.loadingTime) * 100)

          if (this.domStack === 5) {
            this.getOver()
          } else {
            this.domStack++
            this.domStart += this.domSplit
            this.CopyImage(element)
          }
        })
      });
    }
    ,

    /*
    *
    * 下载方法
    * @param steamid
    * @param base64
    *
    * */
    DownLoad(steamid) {

      this.$axios.post('/Api/Image/MakeImage', {
        steamid
      }).then(value => {
        clearInterval(this.timeoutInterval)
        this.timeoutSec = 0
        this.$message.loading({content: '等待下载', key})
        const a = (JSON.parse(value.data.data)[0] + "")
        this.link = a
        this.success()
        let alink = document.createElement('a')
        alink.href = this.link
        // alink.download = steamid
        alink.click()
        this.startDownload = false

        this.$message.success({content: '已下载', key})

      }).catch(() => {
        clearInterval(this.timeoutInterval)
        this.timeoutSec = 0
        this.startDownload = false
        this.$message.error({content: '下载失败！手机用户生成中请不要切换后台屏幕不要熄灭，请重新生成', key})

      })
    },


    success() {
      this.$success({
        title: '如未弹出下载可以复制下面链接手动下载',
        // JSX support
        content: (
            <div>
              <a style="width:100%;text-align:center" href={this.link} target="_blank">
                {location.host + this.link}
              </a>
            </div>
        ),
      });
    },
  },

  watch: {
    seasonTickLarge: {
      // eslint-disable-next-line no-unused-vars
      handler(v, o) {
        if (v) {
          this.bagJs.seasonTicket.splice(this.bagJs.seasonTicket.findIndex(val => val.id == 999), 1)
          this.bagJs.seasonTicket.splice(2, 0, {
            id: 999,
            img_id: 'level',
            item_name: 'GAMELEVEL',
            season_name: "游戏等级"
          })
        } else {
          this.bagJs.seasonTicket.splice(this.bagJs.seasonTicket.findIndex(val => val.id == 999), 1)
          this.bagJs.seasonTicket.splice(1, 0, {
            id: 999,
            img_id: 'level',
            item_name: 'GAMELEVEL',
            season_name: "游戏等级"
          })
        }

      }
    },
    productList: {
      // eslint-disable-next-line no-unused-vars
      handler(oldValue, newValue) {
        //TODO

      }
    },
    isTurnProduct: {
      // eslint-disable-next-line no-unused-vars
      handler(oldValue, newValue) {
        //TODO
      }
    },
    productText: {
      // eslint-disable-next-line no-unused-vars
      handler(oldValue, newValue) {
        //TODO
      }
    },
    timeoutSec: {
      // eslint-disable-next-line no-unused-vars
      handler(v, o) {
        if (v == 180) {
          clearInterval(this.timeoutInterval)
          this.$message.error({content: '加载超时,请重试或者尝试其他方式', key})
          this.isFly = false
          this.startDownload = false
        }
      }
    }
  },
  computed: {
    ...mapState('footer', ['website_info']),
    ...mapState('imgData', ['material']),
  },


  mounted() {
    const temp = JSON.parse(this.$store.getters.bagData)
    this.isDeluxUser = temp.result.some(v => ["Captain's Camo Hat", "Captain's Camo Mask", "Captain's Camo Gloves", "Survivor Base"].includes(v.name))
    temp.seasonTicket.splice(1, 0, {
      id: 999,
      img_id: 'level',
      item_name: 'GAMELEVEL',
      season_name: "游戏等级"
    })
    let result = []
    const smallresult = []
    for (const i of temp.result) {
      result.push({
        classid: nanoid(32),
        icon_url: i.icon_url,
        name: i.name,
        describe: '',
        isTurnBody: false,
        level: i.level ? i.level : 0
      })
    }

    if (temp.smallresult.length) {
      for (const i of temp.smallresult) {
        smallresult.push({
          classid: nanoid(32),
          icon_url: i.icon_url,
          name: i.name,
          describe: '',
          isTurnBody: false,
          level: 0

        })
      }
    }

    result = result.concat(smallresult)
    this.bagJs = {
      ...temp,
      result,
    }

    console.log(this.bagJs)
  },

  created() {
    // const _serializeToString = XMLSerializer.prototype.serializeToString;
    // XMLSerializer.prototype.serializeToString = function (node) {
    //   const value = _serializeToString.call(this, node);
    //
    //   return value.replace(
    //       /background-image:/g,
    //       '-webkit-background-clip: text; background-image:',  // Add the -webkit-background-clip
    //   );
    // };

    const temp = JSON.parse(localStorage.getItem('bagData'))
    this.topTips = `可在Steam市场出售的库存价值: ${temp.price}元`
    if (temp.game_count) {
      this.topTips =
          `${temp.game_time == 0 ? '' : 'PUBG游戏时长' + temp.game_time + '小时\n'}此Steam账号游戏共计${temp.game_count}个\n此号在Steam市场的库存价值为: ${temp.price}`
    }

    this.is_black = temp.is_black
    if (this.is_black) {
      this.blocked = true
    }

    this.$message.info({content: '请等待所有图片加载完成后在使用描边功能,如无法正常使用请刷新', key, duration: 5})
    this.show = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    this.Phone = (/iPhone|iPad/i.test(navigator.userAgent))
    console.log(this.Phone)
    this.showX = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
    // if(this.showX){
    //   document.body.style.zoom = 0.80 * window.devicePixelRatio;
    // }
    window.oncontextmenu = function (e) {
      e.preventDefault();
    };
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.31'


  },

}


</script>



<style scoped>

.bg-clip-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  display: inline-block;
}

body {
  overflow: -Scroll;
  overflow-x: hidden;
}

</style>

<style scoped>




.border_btm {
  border-bottom: 3px solid gray;
}

.noFly {
  margin: 0 auto;
}

.fly {
  position: relative;
  top: 0;
  left: 0;
}

.product-medium {
  height: 165px;
}

.product-large {
  height: 165px;
}

.product-large_1600 {
  height: 190px;
}

.product-very-large {
  height: 130px;
}

.product16 {
  height: 95px;
}


.product16_1600 {
  height: 105px;
}

.product18 {
  height: 90px;
}

.product18_1600 {
  height: 100px;
}
.product-very-large_1600 {
  height: 140px;
}



.level_small {
  width: 70px;
  height: 35px;
  line-height: 35px;
  font-size: 20px;
  font-weight: bold;
}

.level_large {
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 17px;
  font-weight: 600;
}

.level_very_large {
  width: 42px;
  height: 21px;
  line-height: 21px;
  font-size: 13px;
}


.level16{
  width: 32px;
  height: 16px;
  line-height: 18px;
  font-size:11px;
}

.level18 {
  width: 32px;
  height: 16px;
  line-height: 18px;
  font-size:11px;
}


.level16_1600{
  width: 42px;
  height: 22px;
  line-height: 22px;
  font-size:12px;
}

.level18_1600{
  width: 35px;
  height: 18px;
  line-height: 18px;
  font-size:12px;
}


.level_large_1600 {
  width: 80px;
  height: 41px;
  line-height: 41px;
  font-size: 22px;
  font-weight: bold;
}

.level_very_large_1600 {
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: bold
}


@keyframes died {
  from {
    transform: scale(1, 1);

  }

  to {
    transform: scale(0);
  }
}

.selectItem {
  border: 2px rgba(255, 255, 0, 0.8) solid !important;
}

.left_menu {
  position: fixed;
  left: 2%;
  padding: 15px;
  top: 6%;
  height: 840px;
  width: 280px;
  border: 1px solid rgba(2, 29, 164, 0.89);
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.3);
  transform: scale(0.88);
}

.left_menu_hide {
  position: fixed;
  left: -3px;
  top: 5%;
  height: 800px;
  width: 10px;
  border: 1px solid rgba(2, 29, 164, 0.89);
  border-radius: 0 25px 25px 0;
  background: rgba(0, 0, 0, 0.62);
}

.left_menu_button {
  position: absolute;
  top: 45%;
  right: -22px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.left-menu-enter-active, .left-menu-leave-active {
  transition: all .2s linear;
}

.left-menu-enter, .left-menu-leave-to {
  transform: translateX(-100%);
}

.left-menu-leave, .left-menu-enter-to {
  transform: translateX(0px);
}

.cccc {
  transition: all 0.5s;
  color: red;
}

.cccc:hover {
  cursor: pointer;
  transform: rotate(360deg) scale(1.2);
  color: #00b7ee;
}

::-webkit-scrollbar {
  display: none;
}


/*5个*/
.imgFor {
  transition: all .2s;
  height: 156px;
  width: 156px;
  padding: 10px;
}


/*九个*/
.imgFor_large {
  transition: all .2s;
  height: 125px;
  width: 121px;
  padding: 10px;
}


/*16个*/
.imgFor16 {
  transition: all .2s;
  height: 68px;
  width: 68px;
  padding: 5px;
  margin: 3px 0
}


.imgFor16_1600 {
  transition: all .2s;
  height: 100px;
  width: 100px;
  padding: 5px;
  margin: 3px 0
}

.imgFor18_1600 {
  transition: all .2s;
  height: 88px;
  width: 88px;
  padding: 5px;
  margin: 3px 0
}


/*18个*/
.imgFor18 {
  transition: all .2s;
  height: 61px;
  width: 61px;
  padding: 5px;
  margin: 3px 0

}

.imgFor_large_1600 {
  transition: all .2s;
  height: 190px;
  width: 177px;
  padding: 17px;
}

/*十三个*/
.imgFor_very_large {
  transition: all .2s;
  height: 84px;
  width: 84px;
  padding: 10px;
}

.imgFor_very_large_1600 {
  transition: all .2s;
  height: 120px;
  width: 123px;
  padding: 15px;
}


/*杂物*/
.imgFor-it {
  transition: all .2s;
  height: 44px;
  width: 44px;
  padding: 5px;
  float: left;
}


.imgFor-it_1600 {
  transition: all .2s;
  height: 47px;
  width: 47px;
  padding: 5px;
  float: left;
}

.imgFor-cache {
  transition: all .2s;
  height: 80px;
  width: 80px;
  margin: 3px;
  float: left;
}

/*季票*/
.imgFor-Season {
  transition: all .2s;
  height: 170px;
  width: 345px;
  padding: 10px;
}


.imgFor-Season_large {
  transition: all .2s;
  height: 110px;
  width: 220px;
  padding: 5px;
}

.imgFor-Season_large_1600 {
  transition: all .2s;
  height: 150px;
  width: 315px;
  padding: 8px;
}


.download {
  width: 1100px;
  background-size: 100%;
  border-radius: 28px;
}


.download_1600 {
  width: 1600px;
  background-size: 100%;
  border-radius: 28px;
}


.reWrite {
  margin-top: 20px;
  width: 95%;
  text-align: center;
  font-size: 20px;
  color: #9b9b9b;

  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reWriteX {
  margin-top: 15px;
  width: 95%;
  text-align: center;
  font-size: 15px;
  color: #e7e7e7;

  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navigation {
  width: 100%;
  height: 48px;
}


.download_img_box {
  width: 100%;
  background-size: 100%;
  display: flex;
  flex-wrap: wrap;
}

/*1080*/
.download_button_box {
  width: 1100px;
  height: auto;
  background: rgba(0, 0, 0, 0.33);
  border: 1px solid rgba(0, 160, 233, 0.33);
  border-radius: 28px;
  margin: 0 auto 29px;
  padding: 28px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.download_button {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: rgb(79, 142, 251) 1px solid;
  border-radius: 19px;
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.a-button-height {
  height: 40px;
}


.describe {
  width: 100%;
  padding: 15px;
  outline: none;
  background-color: transparent;
  border: none;
  color: red;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}

.describe:focus {
  background-color: #FEDB90;
}


.input_box {
  display: flex;
  width: 600px;
  /*margin: 0 auto;*/
  margin-top: 43px;
}

.input {
  width: 73.5%;
  height: 49px;
  background: rgba(0, 0, 0, 0.33);
  border: 1px solid #00A0E9;
  border-radius: 33px 0 0 33px;
  font-size: 13px;
  font-weight: 300;
  color: #0DAAF5;
  padding-left: 17px;

}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #00A0E9;
  opacity: 1;
}


::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #00A0E9;
  opacity: 1;
}


input:-ms-input-placeholder {
  color: #00A0E9;
  opacity: 1;
}


input::-webkit-input-placeholder {
  color: #00A0E9;
  opacity: 1;
}

.input_button {
  width: 90px;
  height: 49px;
  background: linear-gradient(90deg, #0EA9F5 0%, #3A7AE7 100%);
  border-radius: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}

.input_button_1 {
  width: 90px;
  height: 49px;
  background: linear-gradient(90deg, #eecf0d 0%, #fc0d0d 100%);
  border-radius: 0 33px 33px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
}


</style>

